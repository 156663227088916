import { Severity } from '@sentry/types';
import { EditorSDK, PageData, PageRef, PanelResolveType } from '@wix/platform-editor-sdk';
import { TpaPageId } from '@wix/pricing-plans-router-utils';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import pricingPlansIllustration from '../assets/images/pricing-plans.png';
import { toError } from './errors';

const appDefinitionId = pricingPlans.appDefinitionId;

interface CaptureExceptionOptions {
  print: boolean;
  interactionTag: string;
}

export function captureException(
  flowAPI: EditorScriptFlowAPI,
  err: unknown,
  options: Partial<CaptureExceptionOptions> = {},
) {
  if (typeof err === 'object' && err !== null && !(err instanceof Error)) {
    flowAPI.errorMonitor.addBreadcrumb({
      level: Severity.Error,
      message: 'Error object.',
      data: err,
    });
  }
  flowAPI.errorMonitor.captureException(
    toError(err),
    options.interactionTag
      ? {
          tags: {
            interaction: options.interactionTag,
          },
        }
      : {},
  );
  if (options.print) {
    console.error(err);
  }
}

function toRef(page: PageData): PageRef {
  return { id: page.id!, type: 'DESKTOP' };
}

export async function setPageStates(sdk: EditorSDK) {
  console.log('>>> set page states');
  const pages = await sdk.pages.getApplicationPages('');
  const state: Record<string, PageRef[]> = {
    checkout: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.Checkout).map(toRef),
    paywall: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.Paywall).map(toRef),
    thank_you: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.ThankYou).map(toRef),
  };
  console.log('>>> states', state);
  if (Object.values(state).some((statePages) => statePages.length)) {
    await sdk.pages.setState('', { state });
  }
}

export async function splitPagesMigration(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  flowAPI.fedops.interactionStarted('router_split_pages');
  await markPackagePickerManaged(sdk);

  console.log('>>> install pages');

  const pages = await sdk.pages.getApplicationPages('');
  const tpaPageIds = pages.map((page) => page.tpaPageId);

  if (!tpaPageIds.includes(TpaPageId.Paywall)) {
    await installPage(sdk, TpaPageId.Paywall);
  }

  if (!tpaPageIds.includes(TpaPageId.ThankYou)) {
    await installPage(sdk, TpaPageId.ThankYou);
  }

  if (!tpaPageIds.includes(TpaPageId.Checkout)) {
    await installPage(sdk, TpaPageId.Checkout);
  }
  flowAPI.fedops.interactionEnded('router_split_pages');
}

function installPage(sdk: EditorSDK, tpaPageId: TpaPageId) {
  console.log('>>> install page', tpaPageId);
  return sdk.document.tpa.add.component('', {
    appDefinitionId,
    managingAppDefId: appDefinitionId,
    componentType: 'PAGE',
    page: {
      pageId: tpaPageId,
      isHidden: true,
      shouldNavigate: false,
    },
  });
}

async function markPackagePickerManaged(sdk: EditorSDK) {
  console.log('>>> mark package picker as managed');
  const pages = await sdk.pages.data.getAll('');
  for (const page of pages) {
    if (page.appDefinitionId === appDefinitionId && !page.managingAppDefId && page.id) {
      await sdk.pages.data.update('', {
        pageRef: { id: page.id, type: 'DESKTOP' },
        data: { managingAppDefId: appDefinitionId },
      });
    }
  }
}

export function suggestInstallationFix(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  if (flowAPI.environment.isADI) {
    flowAPI.errorMonitor.captureMessage('Cannot fix broken ADI site.');
    return;
  }

  const t = flowAPI.translations.t;
  sdk.editor
    .openConfirmationPanel('', {
      illustration: pricingPlansIllustration,
      shouldShowIllustration: true,
      headerText: t('es.install-fix-panel.title'),
      descriptionText: t('es.install-fix-panel.description'),
      mainActionText: t('es.install-fix-panel.main-action'),
      secondaryActionText: t('es.install-fix-panel.secondary-action'),
    })
    .then(async (result) => {
      flowAPI.errorMonitor.captureMessage(`Fix action selected: ${result}.`);
      if (result === PanelResolveType.MAIN_ACTION) {
        try {
          flowAPI.fedops.interactionStarted('reinstall_fix');
          await sdk.application.uninstall('', { openConfirmation: false });
          await sdk.tpa.add.application('', {
            appDefinitionId,
            shouldNavigate: true,
            showPageAddedPanel: true,
          });
          flowAPI.fedops.interactionEnded('reinstall_fix');
        } catch (e) {
          captureException(flowAPI, e, {
            interactionTag: 'reinstall_fix',
          });
        }
      } else if (result === PanelResolveType.SECONDARY_ACTION) {
        try {
          flowAPI.fedops.interactionStarted('uninstall_fix');
          await sdk.application.uninstall('', { openConfirmation: false });
          flowAPI.fedops.interactionEnded('uninstall_fix');
        } catch (e) {
          captureException(flowAPI, e, {
            interactionTag: 'uninstall_fix',
          });
        }
      }
    })
    .catch((e) => {
      captureException(flowAPI, e, {
        interactionTag: 'suggest_installation_fix',
      });
    });
}
