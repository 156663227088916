import { ComponentRef, EditorSDK, WidgetDesignPresets } from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { EditorScriptFlowAPI, ConnectedComponentsBuilder, WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';
import { SinglePlanWidgetRole } from '../../constants/elements';
import { PRESETS_BY_STATE, WidgetState } from '../../layout-config';
import { openElementsPanel } from '../../utils/open-elements-panel';
import { configureTextElement, openChangeRibbonTextPanel, openPlanForm, findComponentByRole } from '../../utils/widget';
import { removeIconSpacing, setIconSpacingToDefault } from '../Benefits/layout-utils';
import { getCategoriesData, getElementsData } from './elements-panel-data';
import { openPlanLayoutPanel } from './panels/Layout/open-plan-layout-panel';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;
  builder.configureWidgetDesign((designBuilder) => {
    designBuilder.set({
      title: t('blocks.design.title'),
      presetsTitle: t('blocks.design.presets-title'),
      customHelpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    });
  });
  builder
    .gfpp()
    .set('layout', {
      onClick: (e) => {
        openPlanLayoutPanel({ componentRef: e.detail.componentRef, editorSDK, flowAPI });
      },
    })
    .set('add', {
      onClick: (e) => openPlanElementsPanel({ widgetRef: e.detail.componentRef, editorSDK, flowAPI }),
    });

  if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.BLOCKS_LAYOUT_CUSTOMIZATION)) {
    builder.gfpp('mobile').set('layout', {
      onClick: (e) => {
        openPlanLayoutPanel({ componentRef: e.detail.componentRef, editorSDK, flowAPI, isMobile: true });
      },
    });
  }

  Object.values(WidgetState).forEach((state) => {
    builder.configureState(state, (stateBuilder) => {
      stateBuilder.configureWidgetDesign((designBuilder) => {
        const presets = getPresets(state);
        designBuilder.setPresets(presets);
      });
    });
  });

  builder.configureConnectedComponents(SinglePlanWidgetRole.ImageContainer, (imageContainerBuilder) =>
    configureImage({ builder: imageContainerBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(SinglePlanWidgetRole.RibbonWidget, (ribbonBuilder) =>
    configureRibbon({ builder: ribbonBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(SinglePlanWidgetRole.PlanName, (nameBuilder) =>
    configurePlanName({ builder: nameBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(SinglePlanWidgetRole.PricingWidget, (pricingBuilder) =>
    configurePricing({ builder: pricingBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(SinglePlanWidgetRole.Description, (descriptionBuilder) =>
    configureDescription({ builder: descriptionBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(SinglePlanWidgetRole.PlanDuration, (durationBuilder) =>
    configureDuration({ builder: durationBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(SinglePlanWidgetRole.Button, (buttonBuilder) =>
    configureButton({ builder: buttonBuilder, editorSDK, flowAPI }),
  );

  builder.configureConnectedComponents(SinglePlanWidgetRole.PerksWidget, (perksBuilder) =>
    configureBenefits({ builder: perksBuilder, editorSDK, flowAPI }),
  );

  configureDivider({ builder, editorSDK, flowAPI });
};

function configureImage(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.image') });
  builder.behavior().set({ closed: { selectable: true, hideFromHierarchy: false }, preventHide: true });
  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: t('blocks.gfpp.change-image'),
    })
    .set('settings', { behavior: 'HIDE' })
    .set('link', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });

  builder.configureWidgetDesign((imageDesignBuilder) => {
    imageDesignBuilder.set({
      title: t('blocks.image-design.title'),
      customHelpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    });
    imageDesignBuilder.tabs().addTab((tabBuilder) => {
      tabBuilder.set({ label: t('blocks.image-design.tab-label') });
      tabBuilder.groups().set({ roles: [SinglePlanWidgetRole.Image, SinglePlanWidgetRole.ImageContainer] });

      tabBuilder
        .addSection((sectionBuilder) => {
          sectionBuilder.set({
            state: 'regular',
            category: 'fill',
            label: '',
            priority: 1,
            roles: [SinglePlanWidgetRole.Image],
            styleParam: 'mediaOpacity',
          });
        })
        .addSection((sectionBuilder) => {
          sectionBuilder.set({
            state: 'regular',
            category: 'corners',
            label: '',
            priority: 1,
            roles: [SinglePlanWidgetRole.Image, SinglePlanWidgetRole.ImageContainer],
          });
        });
    });
  });
}

function configureRibbon(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.ribbon') });
  builder
    .gfpp()
    .set('connect', { behavior: 'HIDE' })
    .set('mainAction1', {
      label: t('blocks.ribbon.edit-text'),
      onClick: (e) => openChangeRibbonTextPanel({ editorSDK, componentRef: e.detail.componentRef, flowAPI }),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('design', { behavior: 'DEFAULT' });

  if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.BLOCKS_LAYOUT_CUSTOMIZATION)) {
    builder.gfpp().set('layout', {
      onClick: (e) => openRibbonLayoutPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
    });
    builder.gfpp('mobile').set('layout', {
      onClick: (e) => openRibbonLayoutPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
    });
  }

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });

  builder.configureWidgetDesign((ribbonDesignBuilder) => {
    ribbonDesignBuilder.set({ title: t('blocks.ribbon-design.title'), customHelpId: SINGLE_PLAN_HELP_ARTICLE_ID });
    ribbonDesignBuilder.tabs().addTab((tabBuilder) => {
      tabBuilder.set({ label: t('blocks.ribbon-design.background') });
      tabBuilder.groups().set({
        roles: [SinglePlanWidgetRole.RibbonContainer],
      });
    });
  });
}

function configurePlanName(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.plan-name') });

  configureTextElement({
    builder,
    editorSDK,
    flowAPI,
    editTextLabel: t('blocks.plan-name.gfpp.edit-text'),
    designTextLabel: t('blocks.plan-name.gfpp.design-text'),
  });
}

function configureDescription(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.plan-description') });
  configureTextElement({
    builder,
    editorSDK,
    flowAPI,
    editTextLabel: t('blocks.plan-description.gfpp.edit-text'),
    designTextLabel: t('blocks.plan-description.gfpp.design-text'),
  });
}

function configureDuration(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.plan-duration') });
  configureTextElement({
    builder,
    editorSDK,
    flowAPI,
    editTextLabel: t('blocks.plan-duration.gfpp.edit-text'),
    designTextLabel: t('blocks.plan-duration.gfpp.design-text'),
  });
}

function configureButton(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, flowAPI, editorSDK } = params;
  const t = flowAPI.translations.t;
  builder
    .panel<'StylableButton', 'settings'>('settings')
    .configureControls({ label: { hidden: false }, link: { hidden: true }, icon: { hidden: false } });

  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.button.gfpp.change-text'),
      onClick: (e) =>
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: e.detail.componentRef,
          helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
        }),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('link', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });
}

function configureDivider(params: { builder: WidgetBuilder; editorSDK: EditorSDK; flowAPI: EditorScriptFlowAPI }) {
  const { builder, editorSDK, flowAPI } = params;
  const t = flowAPI.translations.t;
  builder.configureConnectedComponents(SinglePlanWidgetRole.ContentDividerContainer, (dividerBuilder) => {
    dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
    dividerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(SinglePlanWidgetRole.ContentDividerVertical, (dividerBuilder) => {
    dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
    dividerBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('blocks.content-divider.gfpp.design'),
        onClick: (e) =>
          editorSDK.editor.openNativeComponentPanel('', 'design', { componentRef: e.detail.componentRef }),
      })
      .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
      .set('design', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(SinglePlanWidgetRole.ContentDividerHorizontal, (dividerBuilder) => {
    dividerBuilder.set({ displayName: t('blocks.label.content-divider') });
    dividerBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('blocks.content-divider.gfpp.design'),
        onClick: (e) =>
          editorSDK.editor.openNativeComponentPanel('', 'design', { componentRef: e.detail.componentRef }),
      })
      .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
      .set('design', { behavior: 'HIDE' });
  });
}

function configurePricing(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, editorSDK, flowAPI } = params;
  const t = flowAPI.translations.t;

  builder.set({ displayName: t('blocks.label.pricing') });
  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: t('blocks.pricing.gfpp.edit-text'),
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });

  if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.BLOCKS_LAYOUT_CUSTOMIZATION)) {
    builder.gfpp().set('layout', {
      onClick: (e) => openPricingLayoutPanel({ editorSDK, componentRef: e.detail.componentRef, flowAPI }),
    });
    builder.gfpp('mobile').set('layout', {
      onClick: (e) => openPricingLayoutPanel({ editorSDK, componentRef: e.detail.componentRef, flowAPI }),
    });
  }

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
}

function configureBenefits(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
}) {
  const { builder, editorSDK, flowAPI } = params;
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.benefits') });
  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.benefits.gfpp.manage'),
      onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
    })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('add', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });

  builder.gfpp().set('layout', {
    onClick: (e) => openBenefitsLayoutPanel({ editorSDK, componentRef: e.detail.componentRef, flowAPI }),
  });
  builder.gfpp('mobile').set('layout', {
    onClick: (e) => openBenefitsLayoutPanel({ editorSDK, componentRef: e.detail.componentRef, flowAPI }),
  });

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' }).set('mainAction2', { behavior: 'HIDE' });
}

function getPresets(state: WidgetState): WidgetDesignPresets {
  return PRESETS_BY_STATE[state].map(({ id, thumbnailWidth, thumbnailHeight, thumbnailSrc }) => ({
    id,
    layout: {
      width: thumbnailWidth,
      height: thumbnailHeight,
    },
    src: thumbnailSrc,
  }));
}

function openBenefitsLayoutPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.benefits-layout.title'),
      url: getPanelUrl('Benefits', 'LayoutPanel'),
      height: flowAPI.experiments.enabled(TPA_EXPERIMENTS.BLOCKS_LAYOUT_CUSTOMIZATION) ? 525 : 238,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

async function openRibbonLayoutPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.ribbon-layout.title'),
      url: getPanelUrl('Ribbon', 'LayoutPanel'),
      height: 166,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

async function openPricingLayoutPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.pricing-layout.title'),
      url: getPanelUrl('Pricing', 'LayoutPanel'),
      height: 525,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

async function openPlanElementsPanel(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, widgetRef, flowAPI } = params;
  const t = flowAPI.translations.t;

  const benefitsWidget = await findComponentByRole({
    editorSDK,
    controllerRef: widgetRef,
    role: SinglePlanWidgetRole.PerksWidget,
  });

  return openElementsPanel({
    editorSDK,
    widgetRef,
    data: {
      categories: getCategoriesData(t),
      elements: getElementsData(t),
    },
    onExpandComponent: async (role) => {
      if (role === SinglePlanWidgetRole.PerkIcon && benefitsWidget) {
        return setIconSpacingToDefault(editorSDK, benefitsWidget);
      }
    },
    onCollapseComponent: async (role) => {
      if (role === SinglePlanWidgetRole.PerkIcon && benefitsWidget) {
        return removeIconSpacing(editorSDK, benefitsWidget);
      }
    },
  });
}

export { openChangeRibbonTextPanel };
