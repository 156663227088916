import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';
import { SinglePlanWidgetRole } from '../../constants/elements';
import { SinglePlanInteractions } from '../../types/SinglePlanFedops';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.single-plan') });
  builder.gfpp().set('mainAction1', {
    label: t('blocks.gfpp.single-plan-settings'),
    onClick: (e) => {
      openSettingsPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef });
    },
  });
  builder.gfpp().set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });
  builder.gfpp().set('design', { target: { role: SinglePlanWidgetRole.PlanWidget } });
  builder.gfpp().set('add', { target: { role: SinglePlanWidgetRole.PlanWidget } });
  builder.gfpp().set('connect', { behavior: 'HIDE' });
  builder.gfpp().set('layout', { target: { role: SinglePlanWidgetRole.PlanWidget } });
  builder.configureConnectedComponents(SinglePlanWidgetRole.PlanWidget, (planBuilder) => {
    planBuilder.behavior().set({ preventHide: true, closed: { hideFromHierarchy: true, selectable: false } });
  });
  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });

  if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.BLOCKS_LAYOUT_CUSTOMIZATION)) {
    builder.gfpp('mobile').set('layout', { target: { role: SinglePlanWidgetRole.PlanWidget } });
  }
};

function openSettingsPanel(params: { editorSDK: EditorSDK; componentRef: ComponentRef; flowAPI: EditorScriptFlowAPI }) {
  const { editorSDK, componentRef, flowAPI } = params;
  flowAPI.fedops.interactionStarted(SinglePlanInteractions.OpenSettingsPanel);
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.settings-panel.title'),
      url: getPanelUrl('SinglePlanWidget', 'SettingsPanel'),
      height: 200,
      width: 300,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}
