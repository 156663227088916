import { EditorSDK, PanelResolveType, RouterRef } from '@wix/platform-editor-sdk';
import type { PageRoleId } from '@wix/pricing-plans-router-utils';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import pricingPlansIllustration from '../assets/images/pricing-plans.png';
import { splitPagesMigration } from './editor';

const appDefinitionId = pricingPlans.appDefinitionId;
const TOKEN = '';
const ROUTER_PREFIX = 'pricing-plans';

// Allows to identify page by it's purpose/role instead of requiring to use page IDs which are unique per site.
const PackagePickerPageRole = 'PricingPlans' as const;
const CheckoutPageRole = 'Checkout' as const;
const ThankYouPageRole = 'ThankYou' as const;
const PaywallPageRole = 'Paywall' as const;

export async function installRouter(sdk: EditorSDK) {
  console.log('looking for router with pricing-plans prefix ...');
  let routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (!routerRef) {
    console.log('adding router with pricing-plans prefix ...');
    const pages = await sdk.document.pages.getApplicationPages(TOKEN);
    routerRef = await sdk.document.routers.add(TOKEN, { prefix: ROUTER_PREFIX, config: { pages } });
    console.log('added!');
    console.log('connecting pages to router ...');
    await connectAllPagesToRouter(sdk, routerRef);
    console.log('connected!');
  } else {
    console.log('fetching router data ...');
    const routerData = await sdk.document.routers.get(TOKEN, { routerRef });
    console.log('fetched!');
    if (routerData.prefix !== ROUTER_PREFIX) {
      console.log('updating existing router ...');
      await sdk.document.routers.update(TOKEN, { routerRef, prefix: ROUTER_PREFIX });
      console.log('updated!');
    } else {
      console.log('router is up to date.');
    }
    const installedPageRoles = routerData.pages.flatMap((page) => page.pageRoles);
    if (!installedPageRoles.includes(CheckoutPageRole)) {
      await connectCheckoutPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(ThankYouPageRole)) {
      await connectThankYouPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(PaywallPageRole)) {
      await connectPaywallPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(PackagePickerPageRole)) {
      await connectPackagePickerPageToRouter(sdk, routerRef);
    }
  }
}

export async function uninstallRouter(sdk: EditorSDK) {
  console.log('looking for router with pricing-plans prefix ...');
  const routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (routerRef) {
    console.log('removing router ...');
    await sdk.document.routers.remove(TOKEN, { routerRef });
    console.log('removed!');
  } else {
    console.log('not found!');
  }
}

async function connectAllPagesToRouter(sdk: EditorSDK, routerRef: RouterRef) {
  await connectPaywallPageToRouter(sdk, routerRef);
  await connectCheckoutPageToRouter(sdk, routerRef);
  await connectThankYouPageToRouter(sdk, routerRef);
  await connectPackagePickerPageToRouter(sdk, routerRef);
}

const connectPaywallPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Paywall', 'paywall', sdk, routerRef);

const connectCheckoutPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Checkout', 'checkout', sdk, routerRef);

const connectThankYouPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Thank You', 'thank_you', sdk, routerRef);

const connectPackagePickerPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('membership_plan_picker_tpa', 'pricing_plans', sdk, routerRef);

async function connectPageToRouter(tpaPageId: string, pageRole: PageRoleId, sdk: EditorSDK, routerRef: RouterRef) {
  console.log(`connecting ${tpaPageId} page to router ...`);
  await sdk.document.routers.pages.connect(TOKEN, {
    routerRef,
    pageRef: await sdk.document.tpa.getPageRefByTPAPageId(TOKEN, { tpaPageId }),
    pageRoles: [pageRole],
  });
  console.log('connected!');
}

export async function suggestMigratingToRouter(flowAPI: EditorScriptFlowAPI, editorSDK: EditorSDK) {
  const t = flowAPI.translations.t;
  await editorSDK.editor
    .openPromotionalPanel('', {
      illustration: pricingPlansIllustration,
      titleText: t('es.migrate-to-router.modal.title'),
      subtitleText: t('es.migrate-to-router.modal.subtitle'),
      mainActionText: t('es.migrate-to-router.modal.mainAction'),
      contentArray: [t('es.migrate-to-router.modal.bullet-1'), t('es.migrate-to-router.modal.bullet-2')],
      KBLinkText: t('es.migrate-to-router.modal.link-text'),
      helpId: '', // TODO: add article id
    })
    .then(async (result) => {
      switch (result) {
        case PanelResolveType.MAIN_ACTION:
          await migrateToRouter(flowAPI, editorSDK);
          break;
      }
    });
}

async function migrateToRouter(flowAPI: EditorScriptFlowAPI, editorSDK: EditorSDK): Promise<void> {
  flowAPI.fedops.interactionStarted('router_migraton_event');
  const t = flowAPI.translations.t;
  await editorSDK.editor.openProgressBar('', { totalSteps: 3, title: t('es.migrate-to-router.progress.title') });
  await editorSDK.editor.updateProgressBar('', { currentStep: 1, stepTitle: t('es.migrate-to-router.steps.1') });
  await splitPagesMigration(flowAPI, editorSDK);

  const pages = await editorSDK.pages.data.getAll(TOKEN);
  const ppPage = pages.find((page) => page.managingAppDefId === appDefinitionId && page.id);
  if (ppPage) {
    const prefix = ppPage.pageUriSEO;
    console.log(`>> updating package picker page uri seo from ${ppPage.pageUriSEO} to ${prefix}-statics`);
    await editorSDK.pages.data.update(TOKEN, {
      pageRef: await editorSDK.document.components.getById(TOKEN, { id: ppPage?.id! }),
      data: { ...ppPage, pageUriSEO: `${prefix}-statics` },
    });

    await editorSDK.editor.updateProgressBar('', { currentStep: 2, stepTitle: t('es.migrate-to-router.steps.2') });
    const isValidPrefix = (await editorSDK.routers.isValidPrefix('', { prefix })).valid;
    console.log(`>> checking if ${prefix} is a valid prefix: ${isValidPrefix}`);
    if (isValidPrefix) {
      const appPages = await editorSDK.pages.getApplicationPages(TOKEN);
      console.log(`>> adding router with prefix ${prefix} ...`);
      const routerRef = await editorSDK.routers.add(TOKEN, { prefix, config: { pages: appPages } });
      await editorSDK.editor.updateProgressBar('', { currentStep: 3, stepTitle: t('es.migrate-to-router.steps.3') });
      await connectAllPagesToRouter(editorSDK, routerRef);
      flowAPI.fedops.interactionEnded('router_migraton_event');
      await editorSDK.editor.closeProgressBar('', { isError: false });
    }
  } else {
    await editorSDK.editor.closeProgressBar('', { isError: true });
  }
}
